<template>
  <vx-card :title="title">
    <!-- <vs-button
      class="mt-2"
      color="primary"
      type="border"
      icon-pack="feather"
      icon="icon-plus"
      @click="handleCreate()"
      >Create</vs-button
    > -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-button
          class="ml-4 mt-2"
          type="border"
          color="danger"
          v-on:click="handleClose"
          >Back</vs-button
        >
      </div>
    </div>
    <div class="vx-row mb-12 mt-3">
      <div v-bind:class="[detail ? detailShow : '', detailHide]">
        <div class="vx-row mb-6" style="width: 50%">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Territory</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedTerritory"
              :options="optionTerritory"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :max-height="200"
              name="territory"
              :limit="5"
              :disabled="false"
              @input="onselectedTerritory"
              placeholder="Type to search"
              :searchable="true"
              :custom-label="customLableTerritory"
            />
          </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Customer</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedCustomer"
              :options="optionCustomer"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :searchable="true"
              :loading="isLoadingCus"
        :internal-search="false"
              :max-height="200"
              :limit="5"
              :disabled="false"
              placeholder="Type to search"
              @input="onSelectedCus"
              @search-change="handlerSearchCustomer"
              :custom-label="customLableCustomer"
            />
          </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Bill To</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedBillTo"
              :options="optionBillTo"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :searchable="true"
        :internal-search="true"
              :max-height="200"
              :limit="5"
              :disabled="false"
              placeholder="Type to search"
              :custom-label="customLableBillto"
            />
            <span
            class="text-danger text-sm"
            v-show="isErrorBillTo"
            >{{ msgErrorBillto }}</span>
          </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Shipp To</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              name="billto"
              v-model="selectedShippTo"
              :options="optionShippTo"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :searchable="true"
        :internal-search="true"
              :max-height="200"
              :limit="5"
              :disabled="false"
              v-validate="'required'"
              placeholder="Type to search"
              :custom-label="customLableShippTo"
            />
            <span
            class="text-danger text-sm"
            v-show="true"
            >{{ msgErrorShippTo }}</span>
          </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
          <div class="vx-col sm:w-1/3 w-full flex items-center">
            <span>Date</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <datepicker
              name="date"
              :inline="false"
              v-model="dateCo"
              placeholder="Select Date"
              v-validate="'required'"
            ></datepicker>
          </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
          <div class="vx-col sm:w-1/3 w-full flex items-center">
            <span>Request Delivery Date</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <datepicker
              :inline="false"
              v-model="rDeliveryDate"
              placeholder="Select Date"
              v-validate="'required'"
            ></datepicker>
          </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
          <div class="vx-col sm:w-1/3 w-full flex items-center">
            <span>Due Date</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <datepicker
              name="duedate"
              :inline="false"
              v-model="duedate"
              placeholder="Select Date"
              v-validate="'required'"
            ></datepicker>
          </div>
        </div>

        <div style="margin-top:50px">
          <div class="vx-row mb-6" style="width: 100%">
            <div :class="'vx-col w-full '+(indextr==0?'sm:w-3/12':'sm:w-2/12')" style="font-weight: bold;" :key="indextr" v-for="(tr, indextr) in table.data[0].formInputs">
              <span>{{ tr.head }}</span>
            </div>
            <div class="vx-col sm:w-1/12 w-full">
              <span></span>
            </div>
          </div>
          <div class="vx-row mb-6" style="width: 100%" :key="index" v-for="(dt, index) in table.data">
            <div :class="'vx-col w-full '+(index2==0?'sm:w-3/12':'sm:w-2/12')" :key="index2" v-for="(dt2, index2) in dt.formInputs">
              <FormInput
                      :components="dt2" @onSelected="onselected" @search-change="handlerSearchSelect"
                      /> 
            </div>
            <div class="vx-col sm:w-1/12 w-full">
              <vs-td v-if="index == 0" >
            <vx-tooltip text="Add Item">
              <vs-button
                size="small"
                color="green"
                icon-pack="feather"
                icon="icon-plus"
                @click="handleAddItem()"
              />
            </vx-tooltip>
          </vs-td>
          <vs-td v-else>
            <vx-tooltip text="Delete Item">
              <vs-button
                size="small"
                color="red"
                icon-pack="feather"
                icon="icon-x"
                @click="handleRemoveItem(index)"
              />
            </vx-tooltip>
          </vs-td>
            </div>
          </div>
        </div>
        <vs-button
          class="m-6 float-right"
          color="success"
          type="border"
          @click="handleSubmit()"
          >Submit</vs-button>
      </div>
    </div>
  </vx-card>
</template>

<script>
import moment from "moment";
import FormInput from "./form_input.vue"
import Datepicker from "vuejs-datepicker";
export default {
  components :{
    Datepicker,
    FormInput
  },

  data() {
    const id = this.$route.params.id;
    const edit = true;

    return {
      baseUrl: "/api/sfa/v1/customer-order/edit/"+id,
      coId: id,
      detailShow: "core vx-col md:w-1/2 w-full mb-base ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,
      isErrorBillTo:false,
      msgErrorBillto:"",
      isErrorShippTop:false,
      msgErrorShippTo:"",

      editCus:edit,
      editBillTo:edit,
      editShippTo:edit,
      editDate:edit,
      editReqDelDateTo:edit,
      editDueDate:edit,
      editItem:edit,

      countItem:0,

      deleteId: null,
      optionTerritory: [],
      optionCustomer: [],
      optionBillTo: [],
      optionShippTo: [],
      isLoadingCus:false,
      selectedTerritory: {
        id:null,
        code:"-",
        name:"",
      },
      selectedCustomer: {
        code:"",
        name:""
      },
      selectedBillTo: {
        ID:null,
        CustomerID:"",
        Address:"",
      },
      selectedShippTo: {
        ID:null,
        CustomerID:"",
        Address:"",
      },
      searchCust:"",
      searchItem:"",
      dataCustomerOrder:{},
      table: this.tableDefaultState(),
      dateCo:null,
      duedate:null,
      rDeliveryDate:null,
      title:"Form Customer-order",
      id: id,
    };
  },
  methods: {
    tableDefaultState() {
      return {
        data: [
          {
            id:1,
            formInputs:[
              {
                id:1,
                parentId:1,
                disabled: false,
                validate: "required",
                name: "sku_code",
                placeholder: "Sku Code",
                head:"Sku Code",
                type: "multiselect",
                isLoading:false,
                multiple: false,
                allowempty: false,
                value: {},
                optionValue:[],
              },
              {
                id:2,
                parentId:1,
                disabled: false,
                validate: "required",
                name: "qty",
                placeholder: "QTY",
                head:"QTY",
                type: "input",
                value: "",
              },
              {
                id:3,
                parentId:1,
                disabled: false,
                validate: "required",
                name: "price",
                placeholder: "Price",
                head:"Price",
                type: "input",
                value: "",
              },
              {
                id:4,
                parentId:1,
                disabled: false,
                validate: "required",
                name: "discount",
                placeholder: "Discount",
                head:"Discount",
                type: "input",
                value: "",
              },
              {
                id:5,
                parentId:1,
                disabled: false,
                validate: "required",
                name: "tax",
                placeholder: "Tax",
                head:"Tax",
                type: "input",
                value: "",
              },
            ]
        }
        ],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalpage: 1,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    handlerSearchCustomer(search){
      this.searchCust = search
      this.getDataCustomer(this.selectedTerritory)
    },
    getTerritory() {
      this.$vs.loading();
      this.$http
        .get("/api/sfa/v1/route-plan/territory", {
          params: {
            length: 0,
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionTerritory = resp.data.records;
              console.log("this.dataCustomerOrder",this.dataCustomerOrder)
              console.log("this.optionTerritory",this.optionTerritory)
              
              if (this.optionTerritory.length > 0) {
                this.selectedTerritory = this.optionTerritory[0];
                if (this.dataCustomerOrder.territory_id) {
                  this.optionTerritory.forEach(dt=>{
                    if(dt.id==this.dataCustomerOrder.territory_id){
                      console.log(this.selectedTerritory)
                      this.selectedTerritory = dt
                      console.log(dt)
                    }
                  })
                }
              } else {
                this.optionTerritory = [];
                this.selectedTerritory = {};
              }
            } else {
              this.optionTerritory = [];
              this.selectedTerritory = {};
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    onselected(select){
      // console.log(select)
    },
    onSelectedCus(select){
      this.optionBillTo =select.customer_address
      this.optionShippTo =select.customer_address

      this.selectedBillTo = this.optionBillTo[0]
      this.selectedShippTo = this.optionShippTo[0]

      if (this.editBillTo) {
        this.optionBillTo.forEach(dt=>{
          if(dt.id==this.dataCustomerOrder.bill_to_id){
            this.selectedBillTo = dt
          }
        })
        this.editBillTo =false
      }
      
      if (this.editShippTo) {
        this.optionShippTo.forEach(dt=>{
          if(dt.id==this.dataCustomerOrder.ship_to_id){
            this.selectedShippTo = dt
          }
        })
        this.editShippTo = false
      }

    },
    
    customLableTerritory({ code, name }) {
      return `${code} ${name}`;
    },
    customLableCustomer({ code, name }) {
      return `${code} - ${name}`;
    },
    customLableBillto({ CustomerID, Address }) {
      return `${CustomerID} - ${Address}`;
    },
    customLableShippTo({ CustomerID, Address }) {
      return `${CustomerID} - ${Address}`;
    },
    onselectedTerritory(Select){
      this.getDataCustomer(Select)
    },
    handleSubmit() {
      if (this.selectedBillTo&&this.selectedBillTo.ID&&this.selectedShippTo.ID) {
        this.$vs.dialog({
          type: "confirm",
          color: "danger",
          title: `Confirm`,
          text: "Please confirm to submit",
          accept: this.acceptSubmit,
        });
      }
      if (this.selectedBillTo&&this.selectedBillTo){
        if (!this.selectedBillTo.ID&&this.selectedBillTo.ID!=0) {
          this.isErrorBillTo =true
          this.msgErrorBillto ="bill to is required"
        }else{
          this.isErrorBillTo=false
          this.msgErrorBillto =""
        }
      }else{
        this.isErrorBillTo =true
        this.msgErrorBillto ="bill to is required"
      }
      if (this.selectedShippTo&&this.selectedShippTo){
        if (!this.selectedShippTo.ID&&this.selectedShippTo.ID!=0) {
          this.isErrorShippTop =true
          this.msgErrorShippTo ="shipp to is required"
        }else{
          this.isErrorShippTop=false
          this.msgErrorShippTo =""
        }
      }else{
        this.isErrorShippTop =true
        this.msgErrorShippTo ="shipp to is required"
      }
    },   
    acceptSubmit() {
      this.$vs.loading();
      let item =[]
      console.log("this.table.data",this.table.data)
      this.table.data.forEach(dt=>{
        let coLineID = 0
        if (dt.customerOrderLineId) {
          coLineID =dt.customerOrderLineId
        }
        console.log("coLineID",coLineID)
        item.push({
            "customer_order_line_id":coLineID,
            "item_unit_id":dt.formInputs[0].value.id,
            "quantity":dt.formInputs[1].value?parseInt(dt.formInputs[1].value):0,
            "price":dt.formInputs[2].value?parseInt(dt.formInputs[2].value):0,
            "charge":0,
            "discount":dt.formInputs[3].value?parseInt(dt.formInputs[3].value):0,
            "tax":dt.formInputs[4].value?parseInt(dt.formInputs[4].value):0

        })
      })
      const params = {
        "customer_orders":[
            {
                "type":this.dataCustomerOrder.type==""?"regular":this.dataCustomerOrder.type,
                "territory_id":this.selectedTerritory.id,
                "customer_id":this.selectedCustomer.id,
                "billTo_id":this.selectedBillTo.ID,
                "shipTo_id":this.selectedShippTo.ID,
                "date" :this.dateCo&&this.dateCo!="Invalid date"?moment(String(this.dateCo)).format("YYYY-MM-DD"):null,
                "request_delivery_date" :this.rDeliveryDate &&this.rDeliveryDate!="Invalid date"?moment(String(this.rDeliveryDate)).format("YYYY-MM-DD"):null,
                "due_date":this.duedate&&this.duedate!="Invalid date"?moment(String(this.duedate)).format("YYYY-MM-DD"):null,
                // "source":"sfa-web",
                "status":0,
                "Items":item
            }
        ]
      };

      this.$http
        .put("/api/sfa/v1/customer-order/update/"+this.coId, params)
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "The data was successfully submitted",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            // this.$router.push({ name: "customer-order" });
          } else {
            let msg ="",title = ""
            if (resp.data) {
              if(resp.data.messages){
                resp.data.messages.forEach(dt => {
                  this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: dt,
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                  });
                });
              }else{
                if(resp.data[0].StatusCreate){
                  title = resp.data[0].StatusCreate.State
                  msg = resp.data[0].StatusCreate.Message
                  this.$vs.notify({
                    color: "danger",
                    title: title,
                    text: msg,
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                  });
                }else{
                  this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: resp.message+", Status "+resp.status,
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                  });
                }
              }
            }else{
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            }
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log("error Cuy",error);
        });
    },

    handlerSearchSelect(searching,comp) {
      this.getItem(searching,this.table.data[comp.parentId].formInputs)
    },

    handleAddItem() {
      let i_new = this.table.data.length
      this.table.data.push({
            id:i_new+1,
            formInputs:[
              {
                id:1,
                parentId:i_new+1,
                disabled: false,
                validate: "required",
                name: "sku_code",
                placeholder: "Sku Code",
                head:"Sku Code",
                type: "multiselect",
                multiple: false,
                allowempty: false,
                value: {},
                optionValue:[],
              },
              {
                id:2,
                parentId:i_new+1,
                disabled: false,
                validate: "required",
                name: "qty",
                placeholder: "QTY",
                head:"QTY",
                type: "input",
                value: "",
              },
              {
                id:3,
                parentId:i_new+1,
                disabled: false,
                validate: "required",
                name: "price",
                placeholder: "Price",
                head:"Price",
                type: "input",
                value: "",
              },
              {
                id:4,
                parentId:i_new+1,
                disabled: false,
                validate: "required",
                name: "discount",
                placeholder: "Discount",
                head:"Discount",
                type: "input",
                value: "",
              },
              {
                id:5,
                parentId:i_new +1,
                disabled: false,
                validate: "required",
                name: "tax",
                placeholder: "Tax",
                head:"Tax",
                type: "input",
                value: "",
              },
            ]
        })
        this.getItem(null,this.table.data[i_new].formInputs)
    },
    handleRemoveItem(index) {
      this.table.data = this.table.data.filter((v, i) => {
          return i != index;
        });
    },
    handleClose() {
      // back to list
      this.$router.push({ name: "customer-order" });
    },
    getDataCustomer(SelectTerritory) {
      this.isLoadingCus =true
      console.log("this.searchCust:",this.searchCust)
      this.$http
        .get("/api/sfa/v1/customer", {
          params: {
            length: 100,
            // page: this.table.page,
            search: this.searchCust,
            // order: this.table.order,
            // sort: this.table.sort,
            territory_id: SelectTerritory.id,
          },
        })
        .then((resp) => {
          this.isLoadingCus =false
          if (resp.code == 200) {
            if (resp.data.records) {
              // console.log(this.optionCustomer)
              if (resp.data.records.length > 0) {
                if (this.editCus) {
                  resp.data.records.forEach((dt,i)=>{
                    if(dt.id==this.dataCustomerOrder.customer_id){
                      this.selectedCustomer =resp.data.records[i]
                    }
                  })
                  this.searchCust = ""
                  this.editCus =false
                  
                  this.getDataCustomer(this.selectedTerritory)
                  this.onSelectedCus(this.selectedCustomer)
                }else{
                  this.optionCustomer = resp.data.records;
                }
              } else {
                this.optionCustomer = [];
                this.selectedCustomer = {
                  code:"",
                  name:"",
                };
              }
            } else {
              this.optionCustomer = [];
              this.selectedCustomer = {
                  code:"",
                  name:"",
                };
            }
          } else {
            console.log(resp.data);
          }
        })
        .catch((e)=>{
          console.log(e)
        });
    },
    getItem(search,comp)  {
      let idx =0
      if (comp) {
        console.log("comp",comp)
          idx = comp[0].parentId
          idx = idx>0?idx-1:idx
        this.table.data[idx].formInputs[0].isLoading =true
      }else{
        this.table.data.forEach((dt,i) => {
          this.table.data[i].formInputs[0].isLoading =true
        });
      }
      let searchItem =""
      if(search){
        searchItem = search
      }
      this.$http
        .get("/api/sfa/v1/customer-order/list-item-unit", {
          params: {
            length: 100,
            search: searchItem,
          },
        })
        .then((resp) => {
          this.table.data[idx].formInputs[0].isLoading =false
          if (resp.code == 200) {
            let data =[]
            resp.data.records.forEach(dt=>{
              data.push(
                {
                  id:dt.item_unit_id,
                  code:dt.code,
                  text:dt.text
                }
              )
            })
            if (comp) {
              if (!this.editItem) {
                this.table.data[idx].formInputs[0].optionValue = data
              }else{
                data.forEach(dt2=>{
                  if (dt2.id==this.table.data[idx].formInputs[0].value.item_unit_id) {
                    this.table.data[idx].formInputs[0].value =dt2
                  }
                })
                this.countItem--
                if (this.countItem<=0) {
                  this.getItem()
                  this.editItem = false
                }
              }
            }else{
              this.table.data.forEach((dt,i) => {
                this.table.data[i].formInputs[0].optionValue =data
                this.table.data[i].formInputs[0].isLoading =false
              });
            }
          } else {
            console.log("response",resp.data);
          }
        });
    },
    getData() {
      this.$http
        .get(this.baseUrl) 
        .then((resp) => {
          if (resp.code == 200) {
            this.dataCustomerOrder =resp.data
            this.searchCust =  this.dataCustomerOrder.customer_id
            if (this.editDate) {
              this.dateCo = moment(this.dataCustomerOrder.date).format("DD MMM YYYY")
              this.editDate =false
            }
            if (this.editDueDate) {
              console.log(this.dataCustomerOrder)
              this.duedate = moment(this.dataCustomerOrder.due_date).format("DD MMM YYYY")
              this.editDueDate =false
            }
            if (this.editReqDelDateTo) {
              this.rDeliveryDate = moment(this.dataCustomerOrder.request_delivery_date).format("DD MMM YYYY")
              this.editReqDelDateTo =false
            }
            this.getDataCustomer(this.selectedTerritory)
            let data =[]
            console.log("resp.data.customer_order_line",resp.data.customer_order_line)
            if(resp.data.customer_order_line){
              resp.data.customer_order_line.forEach((dt,i)=>{
                i++
                data.push({
                  id:i,
                  customerOrderLineId:dt.ID,
                  formInputs:[
                    {
                      id:1,
                      parentId:i,
                      disabled: false,
                      validate: "required",
                      name: "sku_code",
                      placeholder: "Sku Code",
                      head:"Sku Code",
                      type: "multiselect",
                      isLoading:false,
                      multiple: false,
                      allowempty: false,
                      value: {
                        item_unit_id:dt.item_unit_id,
                        parentId:dt.ID
                      },
                      optionValue:[],
                    },
                    {
                      id:2,
                      parentId:i,
                      disabled: false,
                      validate: "required",
                      name: "qty",
                      placeholder: "QTY",
                      head:"QTY",
                      type: "input",
                      value: ""+dt.quantity,
                    },
                    {
                      id:3,
                      parentId:i,
                      disabled: false,
                      validate: "required",
                      name: "price",
                      placeholder: "Price",
                      head:"Price",
                      type: "input",
                      value: ""+dt.price,
                    },
                    {
                      id:4,
                      parentId:i,
                      disabled: false,
                      validate: "required",
                      name: "discount",
                      placeholder: "Discount",
                      head:"Discount",
                      type: "input",
                      value: ""+dt.discount,
                    },
                    {
                      id:5,
                      parentId:i,
                      disabled: false,
                      validate: "required",
                      name: "tax",
                      placeholder: "Tax",
                      head:"Tax",
                      type: "input",
                      value: ""+dt.tax,
                    },
                  ]
                })
              })
              this.table.data =data
              this.countItem = this.table.data.length
              for (let i = 0; i < this.countItem; i++) {
                this.getItem(this.table.data[i].formInputs[0].value.item_unit_id,this.table.data[i].formInputs)
              }
            }
            this.getTerritory();            
          } else {
            console.log(resp.data);
          }
        });
    },
  },
  filters: {
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("DD MMM YYYY");
      }
    },
    formatCurrency(value) {
      if (value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
  },
  watch: {},
  mounted() {
    // this.dateCo = moment(moment().valueOf());
    // this.duedate = moment(moment().valueOf()).endOf('month')
    
    this.getData();
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
};
</script>

<style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
.vs-table--thead{
  z-index:-1 !important;
  display: none;
}
</style>
